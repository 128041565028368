const i18n = require('../utils/I18N/index');

export const defaults = {
  Lang: {
    __typename: 'Lang',
    active: 'fr'
  }
};

export const resolvers = {
  Mutation: {
    switchLang: async (_, { lang }, { cache }) => {
      await i18n.changeLanguage(lang);

      cache.writeData({
        data: {
          Lang: {
            __typename: 'Lang',
            active: lang
          }
        }
      });

      return lang;
    }
  }
};

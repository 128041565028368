import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { BatchHttpLink } from 'apollo-link-batch-http';
import fetch from 'isomorphic-unfetch';
import { defaults, resolvers } from './resolvers';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
  ? process.env.REACT_APP_GRAPHQL_URL
  : publicRuntimeConfig.API_URL;

let apolloClient = null;

if (!process.browser) {
  global.fetch = fetch;
}

const httpLink = new BatchHttpLink({ uri: GRAPHQL_URL });
function create(initialState) {
  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,
    uri: GRAPHQL_URL,
    fetchOptions: {
      mode: 'no-cors'
    },
    cache: new InMemoryCache().restore(initialState || {}),
    link: httpLink,
    clientState: {
      defaults: defaults,
      resolvers: resolvers
    }
  });
}

export default function initApollo(initialState) {
  if (!process.browser) {
    return create(initialState);
  }

  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
}
